import CountDown from "./countDown";
import { canterIcons, logo, logoSmall, logoTitle } from "../assets";
import { facebook, instagram, linkedIn } from "../assets/icons";
import bgVideo from "../assets/video.mp4";
import { useCountdown } from "../hook/useCountDown";

const ComingSoon = () => {
  const expiryTimestamp = new Date(2024, 5, 4);
  const [days, hours, minutes, seconds] = useCountdown(expiryTimestamp);

  return (
    <div className={`relative flex`}>
      <div className="bg-blue-120  gap-4 h-screen flex justify-start items-center w-full sm:w-[60%]">
        <div className="h-full sm:pt-10 gap-3 sm:gap-7 flex justify-center items-center sm:items-start  flex-col  w-full sm:ml-[24%] md:ml-[15%]">
          <div className="pt-6">
            <p className="text-blue-120  bg-yellow-120 w-fit	p-1 text-[14px] md:text-[16px] Regular md:text-base font-bold leading-5   uppercase ">
              Industrial Investment
            </p>
          </div>

          <h1 className="flex items-start sm:items-start  flex-col gap-3  text-white text-3xl md:text-5xl font-semibold tracking-wider">
            Your Supportive{" "}
            <span className="flex sm:block justify-center sm:justify-start w-full sm:w-fit">
              Partner
            </span>
          </h1>
          <div className="overflow-hidden  ">
            <p className="Regular  max-w-[350px] text-[12px] md:text-[14px] sm:text-start text-center  text-[#F2F2F2]">
              So good. Im so picky about my music and Imagine Dragons NEVER
              disappoints. Thanks guys! Hope i get to see you oerform live one
              day. This is the best play list out there. I listen to it at work
              and i get so much done. That beat though.
            </p>
          </div>
          <div className="bg-yellow-120 flex sm:hidden w-fit justify-center items-center  ">
            <p className="text-blue-120 bg-yellow-120 w-fit	p-1 Regular  text-base font-semibold leading-5  uppercase">
              We are lunching in
            </p>
          </div>

          <div className="flex sm:hidden text-white">
            <div className=" flex justify-center items-center flex-col">
              <div className="flex justify-center items-center">
                <h1 className=" text-5xl sm:text-2xl md:text-5xl mr-2 tracking-wider">
                  {days}
                </h1>
                <h1 className="text-2xl tracking-wider">:</h1>
              </div>
              <p className=" tracking-wider pr-2 mt-2">D</p>
            </div>
            <div className=" flex justify-center items-center flex-col">
              <div className="flex justify-center items-center  ">
                <h1 className=" text-5xl sm:text-2xl md:text-5xl ml-2 mr-2 tracking-wider">
                  {hours}
                </h1>
                <h1 className="text-2xl tracking-wider">:</h1>
              </div>
              <p className=" tracking-wider pr-2 mt-2">H</p>
            </div>
            <div className=" flex justify-center items-center flex-col">
              <div className="flex justify-center items-center  ">
                <h1 className=" text-5xl sm:text-2xl md:text-5xl  ml-2 mr-2 tracking-wider">
                  {minutes}
                </h1>
                <h1 className="text-2xl tracking-wider">:</h1>
              </div>
              <p className=" tracking-wider pr-2 mt-2 uppercase">m</p>
            </div>
            <div className=" flex justify-center items-center flex-col">
              <div className="flex justify-center items-center  ">
                <h1 className=" text-5xl sm:text-2xl md:text-5xl mr-2 ml-2 tracking-wider">
                  {seconds}
                </h1>
              </div>
              <p className=" tracking-wider pr-2 mt-2 uppercase">s</p>
            </div>
          </div>

          <div className="flex sm:hidden items-center  ">
            <div className="icons flex gap-6  ">
              <a
                href="https://www.linkedin.com/company/brk-company-for-industrial-investment/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {linkedIn}
              </a>
              <a
                href="https://www.instagram.com/brkiq"
                target="_blank"
                rel="noopener noreferrer"
              >
                {instagram}
              </a>
              <a
                href="https://www.facebook.com/brkiq"
                target="_blank"
                rel="noopener noreferrer"
              >
                {facebook}
              </a>
            </div>
          </div>
          {/* <div className=" relative  flex flex-col sm:flex-row justify-center items-center">
            <input
              type="text"
              className="Regular appearance-none bg-blue-120 border-white w-[170px] md:w-[240px]  border h-8 md:h-10  placeholder:grid placeholder:place-content-center placeholder:md:pl-3 placeholder:pl-1 text-white placeholder:text-gray-200 focus:placeholder:text-gray-400   placeholder:md:text-[14px] placeholder:text-[11px] "
              placeholder="Enter your email address"
            />
            <button className="bg-yellow-120  ml-4 text-[14px] font-medium text-blue-120 h-8 md:h-10 uppercase p-2 md:p-2 leading-4 whitespace-nowrap Regular">
              Notify Me
            </button>
            <p className="Regular text-sm font-medium leading-4   absolute top-24 text-[#F2F2F2] left-0 ">
              © 2024 Al-Baraka Company. All Rights Reserved.
            </p>
          </div> */}
        </div>
      </div>
      <div className=" sm:flex hidden relative h-screen overflow-hidden w-[0%] sm:w-[40%] md:w-[40%] justify-center items-center">
        <video
          autoPlay
          loop
          muted
          style={{
            position: "absolute",
            width: "100%",
            left: "50%",
            top: "50%",
            height: "100%",
            objectFit: "cover",
            transform: "translate(-50%, -50%)",
          }}
        >
          <source src={bgVideo} type="video/mp4" />
        </video>
        <CountDown expiryTimestamp={expiryTimestamp} />
      </div>
      <div className=" absolute top-0 left-0 ">
        <div className="w-[100vw] flex justify-center sm:justify-start ">
          <div className="bg-yellow-120 h-0.5 w-[100%]  bottom-0 left-0  absolute"></div>

          <div className="  sm:hidden w-20 p-3 flex items-center h-20">
            {logoSmall}
          </div>
          <div className=" bg-yellow-120 w-20 p-3 hidden sm:flex items-center h-20">
            {logo}
          </div>
          <div className="sm:ml-20 mt-4">{logoTitle}</div>
        </div>
      </div>
      <div className="hidden sm:flex absolute top-0 left-0 ">
        <div className="h-[100vh] flex justify-around items-center flex-col">
          <div className="bg-yellow-120 w-0.5 h-[100%]  top-0 right-0  absolute"></div>
          <div className="w-20  p-3 flex items-center h-20">{logo}</div>
          <div>{canterIcons}</div>
          <div className="icons   ">
            <a
              href="https://www.linkedin.com/company/brk-company-for-industrial-investment/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {linkedIn}
            </a>
            <a
              href="https://www.instagram.com/brkiq"
              target="_blank"
              rel="noopener noreferrer"
            >
              {instagram}
            </a>
            <a
              href="https://www.facebook.com/brkiq"
              target="_blank"
              rel="noopener noreferrer"
            >
              {facebook}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ComingSoon;
